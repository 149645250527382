import React from "react"
import SEO from "../components/seo"
import { motion } from "framer-motion"
import reflective from "../images/will-reflective-03.jpg"

const duration = 0.7

const container = {
  visible: {
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.02,
      delayChildren: duration,
    },
  },
}
const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
}

const AboutPage = () => {
  return (
    <>
      <SEO title="About" />
      <motion.section
        variants={container}
        initial="hidden"
        animate="visible"
        className="container"
      >
        <motion.div className="content" variants={item} transition="easeInOut">
          <h1 className="text-jumbo lust-italic text-primary my-16 pb-10 font-normal">
            <i className="first-h">H</i>e<i className="ss02-liga">l</i>
            <i className="ss01">l</i>
            <i className="ss03">o</i>, I'm Wil<i className="hist">l</i>.
          </h1>
        </motion.div>

        <motion.div className="content" variants={item} transition="easeInOut">
          <hr className="block my-8 border-secondary" />
        </motion.div>

        <motion.div className="content" variants={item} transition="easeInOut">
          <p>
            I love Design. The typography, the colors, the creative problem
            solving with multiple moving parts, the work with other parts of the
            company to achieve something greater than one person or one
            department.
          </p>

          <h3 className="font-light">
            You've got to be creative when your name is Will Smith... the easy
            domains are taken.
          </h3>

          <p>
            I have been designing at high-growth
            venture-backed startups for the past 15 years or so. My experience
            encompasses shaping the visual design, UI, and data visualization of
            a B2B SaaS app. I'm a sucker for the well executed basics of a clean
            layout, vibrant color palette, and ample white space around the
            typography.
          </p>
        </motion.div>

        <motion.div className="content" variants={item} transition="easeInOut">
          <img src={reflective} className=" w-full my-16"></img>
        </motion.div>
      </motion.section>
    </>
  )
}

export default AboutPage
